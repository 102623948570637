<template>
  <div class="my-page-payment my-page" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <div class="content">
        <ul class="coupons tight" v-if="state.coupons.length">
          <li class="font-sm" v-for="(c, idx) in state.coupons" :key="idx">
            <div class="wrapper">
              <div class="inner">
                <div class="name">
                  <span>{{ c.couponName }}</span>
                </div>
                <div class="date">
                  <span>유효 기간 : </span>
                  <span>{{ c.usableStartDate }}</span>
                  <span> ~ </span>
                  <span>{{ c.usableEndDate }} </span>
                  <span v-if="c.extinctDay === 0">(오늘까지 사용 가능)</span>
                  <span v-else>({{ $lib.getNumberFormat(c.extinctDay) }}일 후 소멸 예정)</span>
                </div>
                <div class="actions">
                  <button class="btn btn-light font-xs" @click="viewProjects(c)">사용 가능한 프로젝트 보기</button>
                </div>
              </div>
              <div class="side">
                <div>
                  <div class="text font-xs">
                    <b>잔여 금액</b>
                  </div>
                  <div class="font-lg">
                    <span>{{ $lib.getNumberFormat(c.couponPoint) }}원</span>
                  </div>
                </div>
                <button class="btn btn-default font-xs" @click="viewHistory(c)">이용 내역</button>
              </div>
            </div>
          </li>
        </ul>
        <div class="text-center font-sm pt-4 pb-5" v-else-if="state.loaded">쿠폰이 없습니다.</div>
        <div class="status">
          <div class="input-group font-sm">
            <input type="text" :id="`${component.name}CouponNum`" class="border-focus-point font-sm form-control" placeholder="새로운 쿠폰 번호를 입력해주세요." v-model="state.couponNum"/>
            <div class="input-group-append">
              <button class="btn btn-point font-sm" @click="submit()">등록</button>
            </div>
          </div>
        </div>
        <ul class="guide bottom tight font-sm">
          <li>쿠폰 번호를 등록하시면 후원형 프로젝트 참여시 현금처럼 사용할 수 있는 쿠폰 포인트가 충전돼요.</li>
          <li>쿠폰에 따라 사용가능한 프로젝트가 제한돼요.</li>
          <li>쿠폰의 유효 기간을 확인해주세요. 유효 기간이 지날 경우 쿠폰 입력 및 사용에 제한이 있을 수 있어요.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageMyPagePoint";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},
  mixins: [mixin],
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "쿠폰");
      load();
    });

    const state = reactive({
      coupons: [],
      couponNum: "",
      loaded: false
    });

    const viewProjects = (c) => {
      store.commit("openModal", {
        name: "CouponProjects", params: {
          couponId: c.couponId,
          couponName: c.couponName,
          couponScope: c.couponScope
        }
      });
    };

    const viewHistory = (c) => {
      store.commit("openModal", {
        name: "CouponHistory", params: {
          couponId: c.couponId,
          couponName: c.couponName,
          remain: c.couponPoint
        }
      });
    };

    const submit = () => {
      if (!state.couponNum) {
        document.getElementById(`${component.name}CouponNum`).focus();
        return store.commit("setSwingMessage", "쿠폰 번호를 입력해주세요.");
      }

      http.post(`/api/coupons`, {couponNum: state.couponNum}).then(({data}) => {
        store.commit("setSwingMessage", data.message);
        data.code === "201 CREATED" && store.commit("refresh");
      }).catch(httpError());
    };

    const load = () => {
      state.loaded = false;
      http.get("/api/coupons").then(({data}) => {
        state.loaded = true;
        state.coupons = data.body;
      });
    };

    return {component, state, load, viewProjects, viewHistory, submit};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-payment {
  .subject {
    margin-bottom: $px10;
  }

  .content {
    ul.coupons {
      font-size: 0;
      padding-top: $px10;
      margin-bottom: $px25;

      li {
        display: block;
        padding-bottom: $px15;

        > .wrapper {
          border: $px1 solid #eee;
          border-radius: $px4;
          position: relative;
          display: flex;
          justify-content: space-between;

          .btn {
            padding: $px10 $px15;
          }

          > .inner {
            position: relative;
            padding: $px25;

            .name {
              font-weight: 500;
              font-size: $px20;

              > span {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }

            .point {
              position: absolute;
              top: $px1;
              right: 0;
            }

            .date {
              padding-top: $px4;
              font-size: $px12;
            }

            .actions {
              padding-top: $px25;
            }
          }

          > .side {
            background: $colorBackground;
            border-radius: 0 $px4 $px4 0;
            border-left: $px1 solid #eee;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            flex-wrap: wrap;
            width: 20%;
            padding: $px25;
            text-align: center;

            .text {
              margin-bottom: $px3;
              font-weight: 300;
            }

            > .btn {
              margin-top: $px10;
              white-space: nowrap;
            }
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .status {
      text-align: center;
      margin-bottom: $px25;

      .input-group {
        input {
          height: $px60;
          padding: 0 $px15;
          line-height: $px48;
          border-color: #eee;
        }

        .input-group-append {
          width: $px80;

          button {
            padding: 0 $px15;
            width: 100%;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content {
      .status .input-group .input-group-append {
        width: $px70;
      }

      ul {
        &.coupons li > .wrapper {
          > .inner {
            .name {
              > span {
                font-size: $px18;
              }
            }

            .actions {
              padding-top: $px13;

              button {
                width: 100%;
                margin-right: 0;
                margin-top: $px8;
              }
            }
          }

          .side {
            width: auto;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .content {
      ul {
        &.coupons li > .wrapper {
          .side {
            > .btn {
              margin-top: $px25;
            }
          }
        }
      }
    }
  }
}
</style>